import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { combineLatest as observableCombineLatest, of as observableOf, Subject } from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';

import { AvailableLocales, DefaultLocale, LANGUAGE_LOCALE_MAP } from '../+authenticated/shared/locale/locale.helper';
import { getAccountLocale } from '../reducers/account/account.service';
import { AppState } from '../reducers/index';
import { getAuthenticatedUserLocale } from '../reducers/orm/employee/employee.service';

/**
 * Howto add new language: https://differentlab.atlassian.net/wiki/spaces/DEV/pages/617283592/Languages
 */

const languageNames = {
  en: 'English',
  nl: 'Nederlands',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  pl: 'Polski',
  sv: 'Svenska',
  ro: 'Română',
};

@Injectable()
export class LanguageStartup {
  public finishedTranslationLoad = new Subject();

  public constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private config: PrimeNGConfig,
  ) {}

  public init() {
    this.setDefaults();

    observableCombineLatest([
      observableOf(this.getBrowserLang()),
      this.store.select(getAccountLocale),
      this.store.select(getAuthenticatedUserLocale),
      this.route.queryParams,
      //retry after translate service is stable https://github.com/ngx-translate/core/issues/749
      this.finishedTranslationLoad.pipe(startWith(true)),
    ])
      .pipe(
        map(([browserLang, accountLang, userLang, queryParams, subject]) => {
          const localStorageLang = localStorage.getItem('locale');
          return this.determineLanguage(userLang, accountLang, localStorageLang, browserLang, queryParams);
        }),
        //workaround for bug https://github.com/ngx-translate/core/issues/749
        filter((language) => language !== this.translate.currentLang),
      )
      .subscribe((lang) => {
        this.setLanguage(lang);
      });
  }

  private setDefaults() {
    this.translate.addLangs(AvailableLocales);
    this.translate.setDefaultLang(DefaultLocale);
    this.updatePrimeNGTranslations();
  }

  private getBrowserLang() {
    const browserLang: string = this.translate.getBrowserCultureLang();

    if (!browserLang || !AvailableLocales.includes(browserLang)) {
      const slicedLang = browserLang?.slice(0, 2);
      if (LANGUAGE_LOCALE_MAP[slicedLang]) {
        return LANGUAGE_LOCALE_MAP[slicedLang];
      }
      return DefaultLocale;
    }

    return browserLang;
  }

  public getAvailableLanguages() {
    return AvailableLocales.map((locale) => ({
      name: languageNames[locale.slice(0, 2)],
      value: locale,
    }));
  }

  public determineLanguage(userLang, accountLang, localStorageLang, browserLang, queryParams) {
    if (
      queryParams?.language &&
      this.router.url.includes('signup') &&
      AvailableLocales.includes(queryParams.language)
    ) {
      return queryParams.language;
    }

    if (userLang) {
      return userLang;
    }

    if (accountLang) {
      return accountLang;
    }

    if (localStorageLang) {
      return localStorageLang;
    }

    if (browserLang) {
      return browserLang;
    }

    return DefaultLocale;
  }

  public setLanguage(lang) {
    localStorage.setItem('locale', lang);
    this.translate
      .use(lang)
      .pipe(first())
      .subscribe(() => {
        this.updatePrimeNGTranslations();
        this.finishedTranslationLoad.next(null);
      });
  }

  private updatePrimeNGTranslations() {
    this.config.setTranslation({
      dayNamesMin: [
        this.translate.instant('Su'),
        this.translate.instant('Mo'),
        this.translate.instant('Tu'),
        this.translate.instant('We'),
        this.translate.instant('Th'),
        this.translate.instant('Fr'),
        this.translate.instant('Sa'),
      ],
      monthNamesShort: [
        this.translate.instant('Jan'),
        this.translate.instant('Feb'),
        this.translate.instant('Mar'),
        this.translate.instant('Apr'),
        this.translate.instant('May'),
        this.translate.instant('Jun'),
        this.translate.instant('Jul'),
        this.translate.instant('Aug'),
        this.translate.instant('Sep'),
        this.translate.instant('Oct'),
        this.translate.instant('Nov'),
        this.translate.instant('Dec'),
      ],
      monthNames: [
        this.translate.instant('January'),
        this.translate.instant('February'),
        this.translate.instant('March'),
        this.translate.instant('April'),
        this.translate.instant('May'),
        this.translate.instant('June'),
        this.translate.instant('July'),
        this.translate.instant('August'),
        this.translate.instant('September'),
        this.translate.instant('October'),
        this.translate.instant('November'),
        this.translate.instant('December'),
      ],
      today: this.translate.instant('Today'),
      weekHeader: this.translate.instant('Wk'),
    });
  }
}
